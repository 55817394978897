import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, Thunk, Dispatch } from '../store';

export enum DeviceEnum {
    TABLET = 'tablet',
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

interface IDeviceState {
    deviceActive: DeviceEnum;
}

const initialState: IDeviceState = {
    deviceActive: DeviceEnum.DESKTOP
};

// Slice
const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setDeviceActive: (state, action: PayloadAction<DeviceEnum>) => {
            const { payload } = action;
            state.deviceActive = payload;
        }
    }
});

// Reducers
export default deviceSlice.reducer;

// Selectors
export const deviceSelector = (state: RootState) => state.device;

// Actions
const { setDeviceActive } = deviceSlice.actions;

// Thunks
export const changeDeviceActive = (deviceActive: DeviceEnum): Thunk => (dispatch: Dispatch) => {
    console.log('changeDeviceActive: ', deviceActive);
    dispatch(setDeviceActive(deviceActive));
};
