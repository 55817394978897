import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, Thunk, Dispatch } from '../store';
import { IProperty } from '../../model/property.model';
import { IProject } from 'src/model/project.model';

interface PropertyState {
    property: IProperty | IProject | null;
}

const initialState: PropertyState = {
    property: null
};

// Slice
const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setPropertyActive: (state, action: PayloadAction<IProperty | IProject | null>) => {
            const { payload } = action;
            // @ts-ignore
            state.property = payload;
        }
    }
});

// Reducers
export default propertySlice.reducer;

// Selectors
export const propertySelector = (state: RootState) => state.property;

// Actions
const { setPropertyActive } = propertySlice.actions;

// Thunks
export const changePropertyActive = (propertyActive: IProperty | IProject | null): Thunk => (dispatch: Dispatch) => {
    console.log('changePropertyActive: ', propertyActive);
    dispatch(setPropertyActive(propertyActive));
};
