import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import uiReducer from './ui';
import menuReducer from './menu';
import propertyReducer from './property';
import deviceReducer from './device';

const rootReducer = combineReducers({
    ui: uiReducer,
    menu: menuReducer,
    property: propertyReducer,
    device: deviceReducer
});

const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
