import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, Thunk, Dispatch } from '../store';

interface MenuState {
    menuActive: string;
}

const initialState: MenuState = {
    menuActive: ''
};

// Slice
const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuActive: (state, action: PayloadAction<string>) => {
            const { payload } = action;
            state.menuActive = payload;
        }
    }
});

// Reducers
export default menuSlice.reducer;

// Selectors
export const menuSelector = (state: RootState) => state.menu;

// Actions
const { setMenuActive } = menuSlice.actions;

// Thunks
export const changeMenuActive = (menuActive: string): Thunk => (dispatch: Dispatch) => {
    console.log('changeMenuActive: ', menuActive);
    dispatch(setMenuActive(menuActive));
};
